import { memo } from "react";
import Header from "./header";
import Box from "../components/containers/box";

import "./index.css";

const ScreenLayout = memo(({ children }) => (
    <Box id="main-container">
        <Box id="header"><Header /></Box>
        <Box id="body">{children}</Box>
    </Box>
));

export default ScreenLayout;