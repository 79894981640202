import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './app';
import UserService from './service/UserService';
import ReactGA from "react-ga4";

import './index.css';

const container = document.getElementById('root');
const root = createRoot(container);
const renderApp = () => root.render(<App />);
UserService.initKeycloak(renderApp);

const buildEnv = process.env.REACT_APP_BUILD_ENV;
const measurementId= buildEnv && buildEnv==="demo" ? "G-X2504H19Q3" : "G-W7E4E8R33L";
ReactGA.initialize(measurementId);
ReactGA.send("pageview");
