import { useState } from "react";
import Box from "../components/containers/box";
import UserService from "../service/UserService";

import "./header.css";

const HeaderMenu = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    const handleOpen = () => setMenuOpen(true);

    const handleClose = () => setMenuOpen(false);

    const handleLogout = () => {
        UserService.doLogout({
            redirectUri: window.location.origin // Always redirect to origin so that re-login will land  you on origin.
        })
    }

    return (
        <Box>
            <button id="dropdown-menu-btn" onClick={handleOpen}>
                <img src="/images/profile.svg" width="26" height="26" alt="Profile" />
            </button>
            {menuOpen && (
                <Box id="dropdown-menu-overlay" onClick={handleClose}>
                    <Box id="dropdown-menu">
                        <ul>
                            <li>
                                <button onClick={handleLogout}>Log out</button>
                            </li>
                        </ul>
                    </Box>
                </Box>
            )}
        </Box>
    )
}

export default function Header() {
    return (
        <>
            <img src="/images/logo.png" alt="Website logo" width="100px" />
            <HeaderMenu />
        </>
    )
}