import { lazy, memo, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ScreenLayout from './layout';

// A collection of Routes with lazy loaded screens.
const ROUTES_MAP = [
    {
        path: "/", exact: true,
        component: lazy(() => import(
            /* webpackChunkName: "home-page-screen" */
            './screens/home'
        ))
    }
];

const ScreenLoader = props => (
    /* fallback can be customized later */
    <Suspense {...props} fallback="Loading Screen..." />
)

const App = memo(() => (
    <ScreenLayout>
        <BrowserRouter>
            <Routes>
                {ROUTES_MAP.map(
                    ({ component: Component, ...rest }, index) => (
                        <Route {...rest}
                            key={`route-id-${index}`}
                            element={<ScreenLoader><Component /></ScreenLoader>}
                        />
                    )
                )}
            </Routes>
        </BrowserRouter>
    </ScreenLayout>
));

export default App;