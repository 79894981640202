import Keycloak from "keycloak-js";

// Look at the environment variable to determine which Keycloak config to load
const buildEnv = process.env.REACT_APP_BUILD_ENV;
const config = buildEnv === "demo" ? '/keycloak-prod.json' : buildEnv === "dev" ? '/keycloak-dev.json' : '/keycloak-staging.json';
const _kc = new Keycloak(config);

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback) => {
    _kc.init({
        onLoad: 'login-required',
    })
        .then((authenticated) => {
            if (authenticated) {
                onAuthenticatedCallback()
            }
            // doLogin();
        })
        .catch(console.error);
};

const doLogin = _kc.login;

const doLogout = (options = {}) => _kc.logout(options);

const getToken = () => _kc.token;

const isLoggedIn = () => !! _kc.token;

const updateToken = (successCallback) =>
    _kc.updateToken(5)
        .then(successCallback)
        .catch(doLogin);

const getUsername = () => _kc.tokenParsed?.preferred_username;

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));

const isWebAuthnActive = () => _kc.tokenParsed?.webauthn_active;

const getAuthServerUrl = () => _kc.authServerUrl;

const UserService = {
    initKeycloak,
    doLogin,
    doLogout,
    isLoggedIn,
    getToken,
    updateToken,
    getUsername,
    hasRole,
    isWebAuthnActive,
    getAuthServerUrl
};

export default UserService;

